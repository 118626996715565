// ==UserScript==
// @name         Slack in Browser
// @version      0.1
// @description  Automatically redirects to the browser Slack instead of the modal every time.
// @author       Kevin Cox <kevincox@kevincox.ca>
// @match        https://*.slack.com/archives/*
// @icon         https://www.google.com/s2/favicons?domain=slack.com
// @grant        none
// ==/UserScript==

document.location.pathname = document.location.pathname.replace("/archives", "/messages")
